import { IconButton, Theme, Typography } from '@mui/material';
import { DeleteOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { createFileDropHandler, openFileUploader } from '../../api/files';
import { useActionWithConfirmation } from '../../api/useAction';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { FileEditConfig, FileEditData, useFileEdit } from './useFileEdit';

interface Props {
    label?: ReactNode;
    placeholder?: ReactNode;
    disabled?: boolean;
    preventDownload?: boolean;
}

interface UnboundProps extends Props {
    data: FileEditData;
}

interface BoundProps extends Props {
    fileId: string | null | undefined;
    updateFileId: (v: string | null) => void;
    config: FileEditConfig;
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
`;

const FileContainer = styled.div<{ isDragTarget?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    background: ${props => (props.theme as Theme).palette.grey[200]};
    padding: 0.5rem;
    border-radius: ${props => (props.theme as Theme).shape.borderRadius}px;
    border: 3px ${props => (props as any).isDragTarget
        ? `dashed ${(props.theme as Theme).palette.primary.main}`
        :`solid ${(props.theme as Theme).palette.grey[200]}`};

    display: flex;
    flex-flow: row;
    align-items: center;

    cursor: pointer;

    & > :first-child {
        margin-right: 0.5rem;
    }
`;

const PlaceholderContainer = styled(FileContainer)`
    justify-content: center;
    padding: 1rem;
`;

export const FileEditUnbound = (props: UnboundProps) => {
    const { file, upload, remove, download, isLoading } = props.data;
    const removeAction = useActionWithConfirmation(remove, {
        canRun: !!file,
        title: <FormattedMessage id="common.delete" />,
        confirmationHint: <FormattedMessage id="files.removeConfirmation" />,
    });
    const [isDragTarget, setIsDragTarget] = useState<boolean>(false);

    return (
        <Wrapper className="file-editor">
            <Typography variant="caption">{props.label}</Typography>
            {!isLoading && file && <FileContainer onClick={() => !props.preventDownload && download()}>
                <InsertDriveFileOutlined />
                {file.filename}
                <OccupyFreeSpace />
                {!props.disabled && <IconButton size="small" onClick={e => { e.stopPropagation(); removeAction.run(); }}>
                    <DeleteOutlined />
                </IconButton>}
            </FileContainer>}
            
            {!isLoading && !file && (
                <PlaceholderContainer
                    key="placeholder"
                    className="placeholder"
                    isDragTarget={!props.disabled && isDragTarget}
                    onDragEnter={() => setIsDragTarget(true)}
                    onDragLeave={() => setIsDragTarget(false)}
                    onDrop={props.disabled ? undefined : createFileDropHandler(f => { setIsDragTarget(false); upload(f); })}
                    onDragOver={(e: any) => { e.preventDefault(); }}
                    onClick={() => !props.disabled && openFileUploader(f => upload(f))}>
                    {props.placeholder || <FormattedMessage id="files.drop_or_click_to_upload" />}
                </PlaceholderContainer>)}
            
            {isLoading && (
                <PlaceholderContainer key="loading">
                    <LoadingIndicator sizeVariant="s" />
                </PlaceholderContainer>
            )}
        </Wrapper>
    );
}

export const FileEdit = (props: BoundProps) => {
    const data = useFileEdit(props.fileId, {
        onUpload: fi => props.updateFileId(fi._id),
        onRemove: () => props.updateFileId(null),
        ...props.config,
    });

    return <FileEditUnbound {...props} data={data} />;
}
